import { useEffect, useState } from "react";

import { LayoutOutlined } from "@ant-design/icons";
import { BiSolidMessageAltDetail } from "react-icons/bi";
import { BsPerson } from "react-icons/bs";
import { FaBox, FaClipboardCheck, FaMicroblog, FaUser, FaUserTag } from "react-icons/fa";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { useLocation } from "react-router-dom";

import NavigationHeader from "./components/NavigationHeader";
import NavigationItem from "./components/NavigationItem";
import { NavigationContext } from "./contexts/NavigationContext";
import { useAppSelector } from "../../../redux/hooks";

const Navigation = ({
    navbarWidth,
    navbarIsCollapsed,
    setNavbarIsCollapsed,
}: {
    navbarWidth: number;
    navbarIsCollapsed: boolean;
    setNavbarIsCollapsed: (_navbarIsCollapsed: boolean) => void;
}) => {
    const location = useLocation();

    const [selectedPath, setSelectedPath] = useState(location.pathname);

    const unreadCount = useAppSelector(state => state.auth.unreadCount);

    useEffect(() => {
        setSelectedPath(location.pathname);
    }, [location.pathname]);

    return (
        <NavigationContext.Provider
            value={{
                navbarIsCollapsed,
                selectedPath,
                onPathSelect: setSelectedPath,
            }}
        >
            <div style={{ width: navbarWidth }}>
                <div
                    style={{ height: "10vh" }}
                    className={"mt40 horizontal-center"}
                >
                    <span className={"grey-6-color bold fs18"}>
                        SYMN
                    </span>
                </div>

                <div
                    style={{ height: "80vh" }}
                    className={navbarIsCollapsed ? "ph12" : "ph20"}
                >
                    <div>
                        <NavigationHeader label={"Admin"}/>
                    </div>

                    <div className={"mt20"}>
                        <NavigationItem
                            icon={<FaBox />}
                            label={"Orders"}
                            path={"/orders"}
                            value={unreadCount}
                        />
                    </div>

                    <div className={"mt10"}>
                        <NavigationItem
                            icon={<FaUser/>}
                            label={"Resident List"}
                            path={"/residents"}
                        />
                    </div>

                    <div className={"mt10"}>
                        <NavigationItem
                            icon={<FaUserTag/>}
                            label={"Vendor List"}
                            path={"/vendors"}
                        />
                    </div>

                    <div className={"mt10"}>
                        <NavigationItem
                            icon={<FaClipboardCheck/>}
                            label={"Services and Luxe"}
                            path={"/services"}
                        />
                    </div>

                    <div className={"mt10"}>
                        <NavigationItem
                            icon={<FaMicroblog/>}
                            label={"Maintenance Requests"}
                            path={"/maintenance-requests"}
                        />
                    </div>

                    <div className={"mt10"}>
                        <NavigationItem
                            icon={<BiSolidMessageAltDetail />}
                            label={"Push Notifications"}
                            path={"/push-notifications"}
                        />
                    </div>

                    <div className={"mt10"}>
                        <NavigationItem
                            icon={<LayoutOutlined />}
                            label={"Banner setup"}
                            path={"/banner-setup"}
                        />
                    </div>

                    <div className={"mt10"}>
                        <NavigationItem
                            icon={<BsPerson/>}
                            label={"Profile"}
                            path={"/profile"}
                        />
                    </div>
                </div>

                <div
                    style={{ height: "10vh" }}
                    className={navbarIsCollapsed ? "ph12" : "ph20"}
                >
                    <div className={navbarIsCollapsed ? "horizontal-center" : "horizontal-right"}>
                        {navbarIsCollapsed ? <MdOutlineKeyboardDoubleArrowRight
                            className={"grey-4-color pointer fs20"}
                            style={{ marginTop: -20 }}
                            onClick={() => {
                                setNavbarIsCollapsed(false);
                                localStorage.setItem("navbarIsCollapsed", "false");
                            }}
                        /> : <MdOutlineKeyboardDoubleArrowLeft
                            className={"grey-4-color pointer fs26"}
                            onClick={() => {
                                setNavbarIsCollapsed(true);
                                localStorage.setItem("navbarIsCollapsed", "true");
                            }}
                        />}
                    </div>
                </div>
            </div>

        </NavigationContext.Provider>
    );
};

export default Navigation;
