import { ColumnDef } from "@tanstack/react-table";

import Actions from "./components/Actions";
import Cell from "./components/Cell";
import Contacts from "./components/Contacts";
// import Date from "./components/Date";
import Header from "./components/Header";
import Name from "./components/Name";
import { dateOptions } from "../../../../../constants/date";
import { IDeleteOrderBody, IOrder } from "../../../../../types/orders";

export const getColumns = ({
    deleteOrder,
}: {
    deleteOrder: (_data: IDeleteOrderBody) => void
}): ColumnDef<IOrder>[] => [
    {
        id: "info.orderNo",
        accessorKey: "info.orderNo",
        header: () => <Header label={"Order Id"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "source.info.name",
        accessorKey: "source.info.name",
        header: () => <Header label={"Service/Product"}/>,
        cell: ({ row }) => <Name style={{ maxWidth: 300, wordWrap: "break-word" }} isAlignedLeft value={row.original}/>,
    },
    {
        id: "user.info.name",
        accessorFn: row => {
            return `${row.user.info.firstName} ${row.user.info.lastName}`;
        },
        header: () => <Header label={"Resident name"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "item.property.address.city",
        header: () => <Header label={"Address"}/>,
        cell: ({ row }) => <Cell value={`${row.original.property.address.street}, ${row.original.property.address.state}, ${row.original.property.address.city}, ${row.original.property.address.country}` || "-"} />,
    },
    {
        id: "user.info.contacts",
        header: () => <Header label={"Phone, e-mail"}/>,
        cell: ({ row }) => <Contacts value={row.original.user}/>,
    },
    {
        id: "item.createdAt",
        header: () => <Header label={"Date & Time Received"}/>,
        cell: ({ row }) => <Cell value={new Date(row.original.createdAt).toLocaleDateString("en-US", dateOptions).replace(",", "")} />,
    },
    {
        id: "item.item.info.frequency",
        header: () => <Header label={"Frequency"}/>,
        cell: ({ row }) => {
            const item = row.original.item;
            const info = item?.info;

            return <Cell value={item && info && "frequency" in info ? info.frequency : "-"}/>;
        },
    },
    {
        id: "item.item.info.timeframe",
        header: () => <Header label={"Timeframe"}/>,
        cell: ({ row }) => {
            const item = row.original.item;
            const info = item?.info;

            return <Cell value={item && info && "timeframe" in info ? info.timeframe : "-"}/>;
        },
    },
    // {
    //     id: "info.slot",
    //     accessorKey: "info.slot",
    //     header: () => <Header label={"Begin date"}/>,
    //     cell: ({ row }) => <Date value={row.original.item?.beginDate ? row.original.item?.beginDate?.toString() : ""}/>,
    // },
    // {
    //     id: "info.subscription.info.timeframe",
    //     accessorKey: "info.subscription.info.timeframe",
    //     header: () => <Header label={"Timeframe"}/>,
    //     cell: ({ row }) => <Cell value={row.original.item?.info.timeframe || "-"}/>,
    // },
    // {
    //     id: "info.cost",
    //     accessorKey: "info.cost",
    //     header: () => <Header label={"Service cost"}/>,
    //     cell: info => <Cell value={`$${info.getValue()}`}/>,
    // },
    {
        id: "actions",
        cell: ({ row }) => <Actions
            value={row.original}
            deleteOrder={deleteOrder}
        />,
    },
];
